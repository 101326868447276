/* eslint react/jsx-key: off */
import { withStyles } from "@material-ui/core/styles";
import memoize from "lodash/memoize";
import ProductRequestEmbedded from "./ProductRequestEmbedded";
import RequestButton from "./RequestButton";
import ImagePopover from "./ImagePopover";
import QtyInBoxField from "./QtyInBoxField";
import TextFieldQty from "./TextFieldCustomer";
import ProductReferenceField from "./ProductReferenceField";

import React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  Responsive,
  SimpleList,
  ReferenceInput,
  AutocompleteInput,
  TextField,
  TextInput,
  ReferenceField,
  FunctionField,
  Pagination,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  image: { maxHeight: "3rem" },
};

const ProductPagination = (props) => (
  <Pagination rowsPerPageOptions={[100, 200, 300]} {...props} />
);

const ProductFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <ReferenceInput
      source="appUserId"
      reference="appusers"
      label="resources.products.fields.user"
      filter={{ realm: "PM" }}
      alwaysOn
    >
      <AutocompleteInput optionText={(choice) => `${choice.username}`} />
    </ReferenceInput>
    <TextInput source="asin_w_sku" label="ASIN/SKU" alwaysOn />
    {/* 在hook里面设置模糊搜索 */}
  </Filter>
);

const rowClick = memoize((permissions) => (id, basePath, record) => {
  return permissions === "admin"
    ? Promise.resolve("edit")
    : Promise.resolve("edit");
});

const ProductList = ({ classes, permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<ProductFilter permissions={permissions} />}
      sort={{ field: "id", order: "DESC" }}
      perPage={100}
      pagination={<ProductPagination />}
      bulkActionButtons={false}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => record.qty}
          />
        }
        medium={
          <Datagrid
            {...props}
            // rowClick={rowClick(permissions)}
            // expand={<ProductRequestEmbedded permissions={permissions} />}
          >
            <TextField source="id" />
            {/* <ProductReferenceField label="resources.stockoutrequests.fields.product" /> */}

            <FunctionField
              label="resources.products.image_text"
              render={(record) => {
                const { name } = record;
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ImagePopover record={record} />
                    <div title={name} style={{ maxWidth: "200px" }}>
                      {name.length > 60 ? `${name.substring(0, 60)}...` : name}
                    </div>
                  </div>
                );
              }}
            />
            <TextField source="asin" />
            <TextField source="w_sku" label="resources.products.form.w_sku" />

            <TextFieldQty
              source="qty_uk"
              label="resources.products.fields.qty"
            />
            <TextField source="product_weight" />
            <FunctionField
              label="resources.products.fields.product_size"
              render={(record) => {
                const size_l = record.size_l || "";
                const size_w = record.size_w || "";
                const size_h = record.size_h || "";
                return size_l ? `${size_l} x ${size_w} x ${size_h}` : "";
              }}
            />
            <TextField
              source="position_in_warehouse"
              label="resources.products.fields.position"
            />
            <TextField
              source="qtyInBox"
              label="resources.products.fields.qtyInBox"
            />
            <TextField
              source="box_size"
              label="resources.products.fields.box_size"
            />
            <TextField
              source="box_weight"
              label="resources.products.fields.box_weight"
            />
            <ReferenceField
              source="appUserId"
              reference="appusers"
              label="resources.products.fields.user"
            >
              <TextField source="username" />
            </ReferenceField>
            <RequestButton />
          </Datagrid>
        }
      />
    </List>
  );
};

export default withStyles(styles)(ProductList);
