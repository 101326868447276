/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class StockOutButton extends Component {
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.location = '#/stockouts/create?request_id='+record.id+'&product_id='+record.productId+'&qty='+record.qty+'&warehouse='+record.warehouse;
    }

    render() {
        return (
            <Button label="stock_out" onClick={this.handleClick} color="primary" variant="raised">{this.props.translate('resources.stockoutrequests.actions.stock_out')}</Button>
        );
    }
}

StockOutButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(StockOutButton);