import React, { Component } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import Button from "@material-ui/core/Button";
import { translate, EditButton } from "react-admin";

class RequestButton extends Component {
  handleClick = (event) => {
    event.stopPropagation();
    const { record } = this.props;
    window.location = "#/stockinrequests/create?product_id=" + record.id;
  };

  handleClick2 = (event) => {
    event.stopPropagation();
    const { record } = this.props;
    window.location = "#/stockoutrequests/create?product_id=" + record.id;
  };

  render() {
    const { permissions } = this.props;
    return (
      <div style={{ whiteSpace: "nowrap" }}>
        <EditButton {...this.props} />
        {permissions !== "PM" && (
          <>
            &nbsp; &nbsp;
            <Button
              label="stock_in_request"
              onClick={this.handleClick}
              color="primary"
              size="small"
              variant="raised"
            >
              {this.props.translate(
                "resources.products.actions.stock_in_request"
              )}
            </Button>
            &nbsp; &nbsp;
            <Button
              label="stock_out_request"
              onClick={this.handleClick2}
              size="small"
              color="primary"
              variant="raised"
            >
              {this.props.translate(
                "resources.products.actions.stock_out_request"
              )}
            </Button>
          </>
        )}
      </div>
    );
  }
}

RequestButton.propTypes = {
  record: PropTypes.object,
};

const enhance = compose(translate);

export default enhance(RequestButton);
