import React from "react";
import Button from "@material-ui/core/Button";

export default function RequestButton(props) {
  const { record } = props;
  const handleClick = () => {
    window.location =
      "#/stockoutrequests/create?product_id=" + record.productId;
  };
  return (
    <>
      <Button
        label="stock_in_request"
        onClick={handleClick}
        color="primary"
        variant="raised"
        size="small"
        style={{ whiteSpace: "nowrap" }}
      >
        再发
      </Button>
    </>
  );
}
