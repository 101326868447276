/* eslint react/jsx-key: off */
import ProductIcon from "@material-ui/icons/Storage";
// import StockInListEmbedded from './StockInListEmbedded';
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import compose from "recompose/compose";
import HandlingButton from "./HandlingButton";
import StockOutButton from "./StockOutButton";
import ProductReferenceField from "../products/ProductReferenceField";
import StockOutEmbedded from "./StockOutEmbedded";
import DownloadBoxLabelField from "./DownloadBoxLabelField";
import StatusField from "./StatusField";
import NoteField from "./NoteField";
import RequestButton from "./RequestButton";
import CreatedField from "../components/CreatedField";

import React, { Fragment } from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  Responsive,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextField,
  NumberField,
  ReferenceField,
  DateField,
  translate,
} from "react-admin";

import MobileGrid from "../layout/MobileGrid";
import BarcodeLabelField from "./BarcodeLabelField";

export const UserIcon = ProductIcon;

const StockOutRequestFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <ReferenceInput
      source="productId"
      reference="products"
      label="resources.stockoutrequests.fields.product"
      alwaysOn
    >
      <AutocompleteInput optionText={(choice) => `${choice.name}`} />
    </ReferenceInput>
    <ReferenceInput
      source="appUserId"
      reference="appusers"
      label="resources.stockoutrequests.fields.user"
      alwaysOn
    >
      <AutocompleteInput optionText={(choice) => `${choice.username}`} />
    </ReferenceInput>
    <SelectInput
      source="speed"
      alwaysOn
      choices={[
        { id: "same_day", name: "resources.stockoutrequests.speed.same_day" },
        { id: "urgent", name: "resources.stockoutrequests.speed.urgent" },
        { id: "normal", name: "resources.stockoutrequests.speed.normal" },
      ]}
    />
  </Filter>
);

class TabbedDatagrid extends React.Component {
  state = { stocking_out: [], handling: [], stocked_out: [], cancelled: [] };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, status: value });
  };

  render() {
    const { classes, filterValues, translate, permissions, ...props } =
      this.props;

    return (
      <Fragment>
        <Tabs
          fullWidth
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          <Tab
            key="stocking_out"
            label={translate(`resources.stockoutrequests.tab.stocking_out`)}
            value="stocking_out"
          />
          <Tab
            key="handling"
            label={translate(`resources.stockoutrequests.tab.handling`)}
            value="handling"
          />
          <Tab
            key="stocked_out"
            label={translate(`resources.stockoutrequests.tab.stocked_out`)}
            value="stocked_out"
          />
          <Tab
            key="cancelled"
            label={translate(`resources.stockoutrequests.tab.cancelled`)}
            value="cancelled"
          />
        </Tabs>
        <Divider />
        <Responsive
          xsmall={
            <MobileGrid {...props} ids={this.state[filterValues.status]} />
          }
          medium={
            <div>
              {filterValues.status === "stocking_out" && (
                <Datagrid
                  {...props}
                  ids={this.state.stocking_out}
                  // expand={<StockOutEmbedded permissions={permissions} />}
                >
                  <TextField source="id" />
                  <ReferenceField
                    source="appUserId"
                    reference="appusers"
                    label="resources.stockoutrequests.fields.user"
                  >
                    <TextField source="username" />
                  </ReferenceField>
                  <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                  {/* <StatusField /> */}
                  <NumberField source="qty" />
                  <DateField source="stockOutDate" />
                  <TextField source="shippingCompany" />
                  <TextField source="barcode" />
                  <DownloadBoxLabelField />
                  <BarcodeLabelField label="Barcode Label" />
                  <TextField source="speed" />
                  <ReferenceField
                    source="accountId"
                    reference="accounts"
                    label="resources.stockoutrequests.fields.account"
                    allowEmpty={true}
                  >
                    <TextField source="code" />
                  </ReferenceField>
                  <CreatedField label="created" />
                  <NoteField />
                  <EditButton />
                  <RequestButton />
                  {permissions === "WM" ? <HandlingButton /> : ""}
                  {permissions === "WM" ? <StockOutButton /> : ""}
                </Datagrid>
              )}
              {filterValues.status === "handling" && (
                <Datagrid
                  {...props}
                  ids={this.state.handling}
                  // expand={<StockOutEmbedded permissions={permissions} />}
                >
                  <TextField source="id" />
                  <ReferenceField
                    source="appUserId"
                    reference="appusers"
                    label="resources.stockoutrequests.fields.user"
                  >
                    <TextField source="username" />
                  </ReferenceField>
                  <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                  {/* <StatusField /> */}
                  <NumberField source="qty" />
                  <DateField source="stockOutDate" />
                  <TextField source="shippingCompany" />
                  <TextField source="barcode" />
                  <DownloadBoxLabelField />
                  <BarcodeLabelField label="Barcode Label" />
                  <TextField source="speed" />
                  <CreatedField label="created" />
                  <ReferenceField
                    source="accountId"
                    reference="accounts"
                    label="resources.stockoutrequests.fields.account"
                    allowEmpty={true}
                  >
                    <TextField source="code" />
                  </ReferenceField>
                  <NoteField />
                  <EditButton classes="no-wrap" />
                  {permissions === "WM" ? <StockOutButton /> : ""}
                </Datagrid>
              )}
              {filterValues.status === "stocked_out" && (
                <Datagrid
                  {...props}
                  ids={this.state.stocked_out}
                  // expand={<StockOutEmbedded permissions={permissions} />}
                >
                  <TextField source="id" />
                  <ReferenceField
                    source="appUserId"
                    reference="appusers"
                    label="resources.stockoutrequests.fields.user"
                  >
                    <TextField source="username" />
                  </ReferenceField>
                  <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                  {/* <StatusField /> */}
                  <NumberField source="qty" />
                  <DateField source="stockOutDate" />
                  <TextField source="shippingCompany" />
                  <TextField source="barcode" />
                  <DownloadBoxLabelField />
                  <BarcodeLabelField label="Barcode Label" />
                  <TextField source="speed" />
                  <CreatedField label="created" />
                  <ReferenceField
                    source="accountId"
                    reference="accounts"
                    label="resources.stockoutrequests.fields.account"
                    allowEmpty={true}
                  >
                    <TextField source="code" />
                  </ReferenceField>
                  <NoteField />
                </Datagrid>
              )}
              {filterValues.status === "cancelled" && (
                <Datagrid
                  {...props}
                  ids={this.state.cancelled}
                  // expand={<StockOutEmbedded permissions={permissions} />}
                >
                  <TextField source="id" />
                  <ReferenceField
                    source="appUserId"
                    reference="appusers"
                    label="resources.stockoutrequests.fields.user"
                  >
                    <TextField source="username" />
                  </ReferenceField>
                  <ProductReferenceField label="resources.stockoutrequests.fields.product" />
                  <StatusField />
                  <NumberField source="qty" />
                  <TextField source="warehouse" />
                  <DateField source="stockOutDate" />
                  <TextField source="shippingCompany" />
                  <TextField source="barcode" />
                  <DownloadBoxLabelField />
                  <TextField source="speed" />
                  <CreatedField label="created" />
                  <ReferenceField
                    source="accountId"
                    reference="accounts"
                    label="resources.stockoutrequests.fields.account"
                    allowEmpty={true}
                  >
                    <TextField source="code" />
                  </ReferenceField>
                  <NoteField />
                </Datagrid>
              )}
            </div>
          }
        />
      </Fragment>
    );
  }
}

const StockOutRequestList = ({ permissions, ...props }) => (
  <List
    {...props}
    filterDefaultValues={{ status: "stocking_out" }}
    sort={{ field: "stockOutDate", order: "DESC" }}
    perPage={25}
    bulkActionButtons={false}
    filters={<StockOutRequestFilter />}
  >
    <TabbedDatagrid permissions={permissions} />
  </List>
);

const enhance = compose(translate);

export default enhance(StockOutRequestList);
