/* eslint react/jsx-key: off */
import React from "react";
import PropTypes from "prop-types";
import {
  Edit,
  TabbedForm,
  FormTab,
  SelectInput,
  ReferenceInput,
  TextInput,
  NumberInput,
  DateInput,
  required,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  hidden: {
    display: "none",
  },
};

const StockInRequestEdit = ({ permissions, ...props }) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="resources.stockinrequests.form.summary" path="">
        <ReferenceInput
          source="productId"
          reference="products"
          perPage={100}
          validate={required()}
          label="resources.stockinrequests.fields.product"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="boxes" defaultValue="" validate={required()} />
        <NumberInput source="qtyInBox" defaultValue="" validate={required()} />
        <NumberInput
          source="weightPerBox"
          defaultValue=""
          validate={required()}
        />
        <NumberInput
          source="qtyInTailBox"
          defaultValue=""
          validate={required()}
        />
        <NumberInput source="qty" defaultValue="" validate={required()} />
        <NumberInput
          source="qtyPending"
          defaultValue=""
          validate={required()}
        />
        <TextInput source="waybill" defaultValue="" validate={required()} />
        <DateInput source="estArrivalDate" />
        <TextInput source="shippingCompany" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

StockInRequestEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.string,
};

export default withStyles(styles)(StockInRequestEdit);
