import React from 'react';
import { Show, EditButton, TextField, NumberField, ReferenceManyField, Datagrid, DateField, ReferenceField, Pagination, translate } from 'react-admin';
import compose from 'recompose/compose';
import StockInStatusField from '../stock_in_requests/StatusField';
import StockInEmbedded from '../stock_in_requests/StockInEmbedded';
import StockInButton from '../stock_in_requests/StockInButton';
import StockOutEmbedded from '../stock_out_requests/StockOutEmbedded';
import StockOutButton from '../stock_out_requests/StockOutButton';
import StockOutStatusField from '../stock_out_requests/StatusField';
import DownloadBoxLabelField from '../stock_out_requests/DownloadBoxLabelField';
import NoteField from '../stock_out_requests/NoteField';

const ProductRequestEmbedded = function({ permissions, ...props }) {
    
    return (
    /* Passing " " as title disables the custom title */
    <div>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.products.record.stock_in_request')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="stockinrequests"
                        target="productId"
                        pagination={<Pagination />}
                    >
                        <Datagrid expand={<StockInEmbedded permissions={permissions} />}>
                            <TextField source="id" />
                            <ReferenceField source="appUserId" reference="appusers" label="resources.stockinrequests.fields.user" >
                                <TextField source="username" />
                            </ReferenceField>
                            <StockInStatusField />
                            <NumberField source="boxes" />
                            <NumberField source="qtyInBox" />
                            <NumberField source="weightPerBox" />
                            <NumberField source="qtyInTailBox" />
                            <NumberField source="qty" />
                            <NumberField source="qtyPending" />
                            <TextField source="warehouse" />
                            <TextField source="waybill" />
                            <DateField source="estArrivalDate" />
                            <TextField source="shippingCompany" />
                            <EditButton />
                            {permissions === 'WM' ? <StockInButton /> : "" }
                        </Datagrid>
                </ReferenceManyField>
        </Show>
        <h4 style={{fontWeight: 'normal'}}>{props.translate('resources.products.record.stock_out_request')}:</h4>
        <Show {...props}>
                <ReferenceManyField
                        reference="stockoutrequests"
                        target="productId"
                        pagination={<Pagination />}
                    >
                        <Datagrid expand={<StockOutEmbedded permissions={permissions} />}>
                            <TextField source="id" />
                            <ReferenceField source="appUserId" reference="appusers" label="resources.stockoutrequests.fields.user" >
                                <TextField source="username" />
                            </ReferenceField>
                            <StockOutStatusField />
                            <NumberField source="qty" />
                            <TextField source="warehouse" />
                            <DateField source="stockOutDate" />
                            <TextField source="shippingCompany" />
                            <TextField source="barcode" />
                            <DownloadBoxLabelField />
                            <TextField source="innerPage" />
                            <TextField source="speed" />
                            <NoteField />
                            <EditButton />
                            {permissions === 'WM' ? <StockOutButton /> : "" }
                        </Datagrid>
                </ReferenceManyField>
        </Show>
    </div>
    );
}

ProductRequestEmbedded.propTypes = Show.propTypes;

const enhance = compose(
    translate
);

export default enhance(ProductRequestEmbedded);




