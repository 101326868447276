import React from "react";
import { FunctionField } from "react-admin";
import moment from "moment";

export default function CreatedField(props) {
  const { record } = props;
  return (
    <FunctionField
      label="created"
      render={() => {
        const t = moment(record.created).format("YYYY/MM/DD HH:mm");
        return <span>{t}</span>;
      }}
      {...props}
    />
  );
}
