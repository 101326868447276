/* eslint react/jsx-key: off */
import React from "react";
import {
  Create,
  FormTab,
  Edit,
  SaveButton,
  TabbedForm,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  ImageInput,
  ImageField,
  Toolbar,
  required,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";

import Aside from "./Aside";

const ProductEditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveButton
      label="resources.actions.save_and_show"
      redirect="show"
      submitOnEnter={true}
    />
    {permissions === "admin" && (
      <SaveButton
        label="resources.actions.save_and_next"
        redirect={false}
        submitOnEnter={false}
        variant="flat"
      />
    )}
  </Toolbar>
);

const styles = {
  inlineBlock: { display: "inline-flex", marginRight: "8px", width: "80px" },
};

const ProductCreate = ({ permissions, ...props }) => (
  <Create {...props} aside={<Aside />}>
    <TabbedForm toolbar={<ProductEditToolbar permissions={permissions} />}>
      <FormTab label="resources.products.form.summary" path="">
        <TextInput source="name" defaultValue="" validate={required()} />
        <NumberInput source="product_weight" />
        <NumberInput
          formClassName={props.classes.inlineBlock}
          source="size_l"
          label="resources.products.form.l"
        />
        <NumberInput
          formClassName={props.classes.inlineBlock}
          source="size_w"
          label="resources.products.form.w"
        />
        <NumberInput
          formClassName={props.classes.inlineBlock}
          source="size_h"
          label="resources.products.form.h"
        />
        <TextInput source="asin" defaultValue="" />
        <TextInput
          source="wsku"
          label="resources.products.form.w_sku"
          defaultValue=""
        />
        <TextInput
          source="qtyInBox"
          label="resources.products.fields.qtyInBox"
          defaultValue=""
        />
        <TextInput
          source="box_size"
          label="resources.products.fields.box_size"
          defaultValue=""
        />
        <TextInput
          source="box_weight"
          label="resources.products.fields.box_weight"
          defaultValue=""
        />
        <ReferenceInput
          source="appUserId"
          reference="appusers"
          label="resources.products.fields.user"
          defaultValue={
            JSON.parse(localStorage.getItem("lbtoken")).value.userId
          }
        >
          <SelectInput optionText="username" />
        </ReferenceInput>

        <ImageInput
          source="image"
          accept="image/*"
          label="resources.products.fields.image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default withStyles(styles)(ProductCreate);
