/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceField } from 'react-admin'; // eslint-disable-line import/no-unresolved


const StockInShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="resources.stockins.fields.product" source="productId" reference="products">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="qty" />
            <TextField source="warehouse" />
            <DateField source="stockInDate" />
            <ReferenceField label="resources.stockins.fields.stock_in_request" source="stockInRequestId" reference="stockinrequests">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField label="resources.stockins.fields.user" source="appUserId" reference="appusers">
                <TextField source="username" />
            </ReferenceField>

        </SimpleShowLayout>
    </Show>
);

StockInShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default StockInShow;
