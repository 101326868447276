import React from "react";

const TextField = ({ source, record = {} }) => {
  const value = record[source];
  const style1 = {
    color: "red",
  };
  const style2 = {};
  const retStyle = value <= 0 ? style1 : style2;
  return <span style={retStyle}>{record[source]}</span>;
};

export default TextField;
