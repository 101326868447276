/* eslint react/jsx-key: off */
import React, { Component, createRef } from "react";
import { Tooltip, Popover, Popper } from "@material-ui/core";

const imgStyle = {
  height: "48px",
  maxHeight: "3rem",
  position: "relative",
  margin: "0.5rem",
};

const popoverStyle = {
  maxHeight: "30rem",
  zIndex: "999999",
};

class ImagePopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: this.props.record.image,
      hoverShow: false,
    };
    this.alt = this.state.src
      ? this.state.src.substr(this.state.src.lastIndexOf("/") + 1)
      : "image";
    this.myRef = React.createRef();
  }

  mouseOver = () => {
    this.setState({ hoverShow: true });
  };

  mouseOut = () => {
    this.setState({ hoverShow: false });
  };

  render() {
    return (
      <div>
        <Popper
          style={{ zIndex: 1 }}
          className="fixed-right-bottom"
          placement="right-start"
          open={this.state.hoverShow}
          anchorEl={this.myRef.current}
        >
          <img
            src={
              this.state.src
                ? this.state.src.replace(
                    "s3.amazonaws.com",
                    "s3.us-east-1.amazonaws.com"
                  )
                : ""
            }
            style={popoverStyle}
            alt={this.alt}
          />
        </Popper>
        <img
          ref={this.myRef}
          src={
            this.state.src
              ? this.state.src.replace(
                  "s3.amazonaws.com",
                  "s3.us-east-1.amazonaws.com"
                )
              : ""
          }
          onMouseOver={this.mouseOver}
          onMouseOut={this.mouseOut}
          style={imgStyle}
          alt={this.alt}
        />
      </div>
    );
  }
}

ImagePopover.defaultProps = {
  source: "image",
  addLabel: true,
};

export default ImagePopover;
