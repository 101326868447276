/* eslint react/jsx-key: off */
import React from "react";
import {
  Create,
  FormTab,
  SaveButton,
  TabbedForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  LongTextInput,
  DateInput,
  NumberInput,
  FileInput,
  FileField,
  ImageInput,
  ImageField,
  Toolbar,
  required,
} from "react-admin";

const StockOutRequestEditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveButton
      label="resources.actions.save_and_show"
      redirect="list"
      submitOnEnter={true}
    />
  </Toolbar>
);

const StockOutRequestCreate = function ({ permissions, ...props }) {
  const urlParams = new URLSearchParams(props.location.search);
  const productId = urlParams.get("product_id")
    ? parseInt(urlParams.get("product_id"))
    : "";
  const userId = JSON.parse(localStorage.getItem("lbtoken")).value.userId;

  return (
    <Create {...props}>
      <TabbedForm
        toolbar={<StockOutRequestEditToolbar permissions={permissions} />}
      >
        <FormTab label="resources.stockoutrequests.form.summary" path="">
          <ReferenceInput
            source="productId"
            label="resources.stockoutrequests.fields.product"
            reference="products"
            perPage={100}
            validate={required()}
            defaultValue={productId}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput source="qty" defaultValue="" validate={required()} />

          <DateInput source="stockOutDate" defaultValue={new Date()} />
          <SelectInput
            source="shippingCompany"
            choices={[
              { id: "Fedex", name: "Fedex" },
              { id: "DHL", name: "DHL" },
              { id: "Royal Mail", name: "Royal Mail" },
              { id: "UPS", name: "UPS" },
            ]}
            defaultValue="UPS"
          />
          <FileInput
            source="boxLabel"
            label="resources.stockoutrequests.fields.boxLabel"
          >
            <FileField source="src" title="title" />
          </FileInput>
          <ImageInput
            source="images"
            label="resources.stockoutrequests.fields.images"
            multiple
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source="barcode" />
          <FileInput
            source="barcodeLabel"
            label="resources.stockoutrequests.fields.barcodeLabel"
          >
            <FileField source="src" title="title" />
          </FileInput>
          <SelectInput
            source="speed"
            allowEmpty
            emptyValue=""
            choices={[
              {
                id: "same_day",
                name: "resources.stockoutrequests.speed.same_day",
              },
              { id: "urgent", name: "resources.stockoutrequests.speed.urgent" },
              { id: "normal", name: "resources.stockoutrequests.speed.normal" },
            ]}
          />
          <SelectInput
            source="status"
            choices={[
              {
                id: "stocking_out",
                name: "resources.stockoutrequests.tab.stocking_out",
              },
              {
                id: "handling",
                name: "resources.stockoutrequests.tab.handling",
              },
              {
                id: "stocked_out",
                name: "resources.stockoutrequests.tab.stocked_out",
              },
              {
                id: "cancelled",
                name: "resources.stockoutrequests.tab.cancelled",
              },
            ]}
            defaultValue="stocking_out"
            validate={required()}
          />
          <ReferenceInput
            source="appUserId"
            reference="appusers"
            label="resources.stockoutrequests.fields.user"
            validate={required()}
            defaultValue={userId}
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
          <ReferenceInput
            source="accountId"
            reference="accounts"
            label="resources.stockoutrequests.fields.account"
            validate={required()}
            perPage={100}
          >
            <SelectInput optionText="code" />
          </ReferenceInput>

          <LongTextInput source="note" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default StockOutRequestCreate;
