import React, { Component } from "react";
import "./App.css";
import { Admin, Resource } from "react-admin";
import loopbackClient from "react-admin-loopback";
import addUploadFeature from "./addUploadFeature";
import { authProvider } from "./authProvider";
import i18nProvider from "./i18nProvider";
import Dashboard from "./dashboard";
import customRoutes from "./routes";
import { Layout } from "./layout";
import { Login } from "./auth";
import products from "./products";
import users from "./users";
import stockinrequests from "./stock_in_requests";
import stockins from "./stock_ins";
import stockoutrequests from "./stock_out_requests";
import stockouts from "./stock_outs";
import accounts from "./accounts";

const endpoint = "http://localhost:3000";
const endpoint2 = "https://ukkbwarehouse.com/api";

const endpointResult =
  process.env.NODE_ENV === "development" ? endpoint : endpoint2;

const dataProvider = loopbackClient(endpointResult);
const uploadCapableDataProvider = addUploadFeature(dataProvider);

class App extends Component {
  render() {
    return (
      <div>
        <Admin
          title="PL Warehouse"
          loginPage={Login}
          appLayout={Layout}
          dashboard={Dashboard}
          customRoutes={customRoutes}
          dataProvider={uploadCapableDataProvider}
          authProvider={authProvider(`${endpointResult}/AppUsers/login`)}
          locale="cn"
          i18nProvider={i18nProvider}
        >
          {(permissions) => [
            <Resource name="products" {...products} />,
            <Resource name="appusers" {...users} />,
            <Resource name="stockinrequests" {...stockinrequests} />,
            <Resource name="stockins" {...stockins} />,
            <Resource name="stockoutrequests" {...stockoutrequests} />,
            <Resource name="stockouts" {...stockouts} />,
            <Resource name="accounts" {...accounts} />,
          ]}
        </Admin>
      </div>
    );
  }
}

export default App;
