import React from "react";
import ImagePopover from "./ImagePopover";
import pure from "recompose/pure";

const ProductImageField = ({ record = {}, size }) => {
  return (
    <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
      <ImagePopover record={record} />
      &nbsp;{record.name}
    </div>
  );
};

const PureProductImageField = pure(ProductImageField);

PureProductImageField.defaultProps = {
  source: "name",
  label: "resources.products.fields.name",
};

export default PureProductImageField;
