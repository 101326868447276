/* eslint react/jsx-key: off */
import React from "react";
import {
  Edit,
  FormTab,
  SaveButton,
  TabbedForm,
  TextInput,
  SelectInput,
  DisabledInput,
  ReferenceInput,
  DateInput,
  Toolbar,
  required,
} from "react-admin";

const StockInEditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveButton
      label="resources.actions.save_and_show"
      redirect="show"
      submitOnEnter={true}
    />
    {permissions === "admin" && (
      <SaveButton
        label="resources.actions.save_and_next"
        redirect={false}
        submitOnEnter={false}
        variant="flat"
      />
    )}
  </Toolbar>
);

const StockInEdit = function ({ permissions, ...props }) {
  const urlParams = new URLSearchParams(props.location.search);

  return (
    <Edit {...props}>
      <TabbedForm toolbar={<StockInEditToolbar permissions={permissions} />}>
        <FormTab label="resources.stockins.form.summary" path="">
          <TextInput source="qty" defaultValue="" validate={required()} />
          <DateInput source="stockInDate" defaultValue={new Date()} />
          <ReferenceInput
            source="productId"
            reference="products"
            perPage={100}
            validate={required()}
            label="resources.stockins.fields.product"
            defaultValue={parseInt(urlParams.get("product_id"))}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="stockInRequestId"
            reference="stockinrequests"
            label="resources.stockins.fields.stock_in_request"
            validate={required()}
            defaultValue={parseInt(urlParams.get("request_id"))}
          >
            {/* <DisabledInput source="id" /> */}
            <TextInput source="id" disabled />
          </ReferenceInput>
          <ReferenceInput
            source="appUserId"
            reference="appusers"
            validate={required()}
            label="resources.stockins.fields.user"
            defaultValue={
              JSON.parse(localStorage.getItem("lbtoken")).value.userId
            }
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default StockInEdit;
