/* eslint react/jsx-key: off */
import React from "react";
import {
  Edit,
  FormTab,
  SaveButton,
  TabbedForm,
  TextInput,
  SelectInput,
  DisabledInput,
  ReferenceInput,
  DateInput,
  Toolbar,
  required,
} from "react-admin";

const StockOutEditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveButton
      label="resources.actions.save_and_show"
      redirect="show"
      submitOnEnter={true}
    />
    {permissions === "admin" && (
      <SaveButton
        label="resources.actions.save_and_next"
        redirect={false}
        submitOnEnter={false}
        variant="flat"
      />
    )}
  </Toolbar>
);

const StockOutEdit = function ({ permissions, ...props }) {
  const urlParams = new URLSearchParams(props.location.search);

  return (
    <Edit {...props}>
      <TabbedForm toolbar={<StockOutEditToolbar permissions={permissions} />}>
        <FormTab label="resources.stockouts.form.summary" path="">
          <TextInput source="qty" defaultValue="" validate={required()} />
          <DateInput source="stockOutDate" defaultValue={new Date()} />
          <ReferenceInput
            source="productId"
            reference="products"
            label="resources.stockouts.fields.product"
            perPage={100}
            validate={required()}
            defaultValue={parseInt(urlParams.get("product_id"))}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="stockOutRequestId"
            reference="stockoutrequests"
            label="resources.stockouts.fields.stock_out_request"
            validate={required()}
            defaultValue={parseInt(urlParams.get("request_id"))}
          >
            {/* <DisabledInput source="id" /> */}
            <TextInput source="id" disabled />
          </ReferenceInput>
          <ReferenceInput
            source="appUserId"
            reference="appusers"
            validate={required()}
            label="resources.stockouts.fields.user"
            defaultValue={
              JSON.parse(localStorage.getItem("lbtoken")).value.userId
            }
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default StockOutEdit;
