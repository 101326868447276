/* eslint react/jsx-key: off */
import ProductIcon from "@material-ui/icons/Storage";
import memoize from "lodash/memoize";
// import StockInListEmbedded from './StockInListEmbedded';
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import compose from "recompose/compose";
import StockInButton from "./StockInButton";
import ProductReferenceField from "../products/ProductReferenceField";
import StockInEmbedded from "./StockInEmbedded";
import StatusField from "./StatusField";
import CreatedField from "../components/CreatedField";

import React, { Fragment } from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  Responsive,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
  FunctionField,
  TextField,
  NumberField,
  ReferenceField,
  DateField,
  translate,
} from "react-admin";

import MobileGrid from "../layout/MobileGrid";

export const UserIcon = ProductIcon;

const cardStyle = {
  width: 300,
  minHeight: 300,
  margin: "0.5em",
  display: "inline-block",
  verticalAlign: "top",
};

const CommentGrid = ({ ids, data, basePath }) => (
  <div style={{ margin: "1em" }}>
    {ids.map((id) => (
      <Card key={id} style={cardStyle}>
        <CardHeader
          title={<TextField record={data[id]} source="author.name" />}
          subheader={<DateField record={data[id]} source="created_at" />}
          avatar={<span>1</span>}
        />
        <CardContent>
          <TextField record={data[id]} source="body" />
        </CardContent>
        <CardContent>
          about&nbsp;
          <ReferenceField
            label="Post"
            resource="comments"
            record={data[id]}
            source="post_id"
            reference="posts"
            basePath={basePath}
          >
            <TextField source="title" />
          </ReferenceField>
        </CardContent>
        <CardActions style={{ textAlign: "right" }}>
          <EditButton resource="posts" basePath={basePath} record={data[id]} />
        </CardActions>
      </Card>
    ))}
  </div>
);

const StockInRequestFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <ReferenceInput
      source="productId"
      reference="products"
      label="resources.stockinrequests.fields.product"
      alwaysOn
    >
      <AutocompleteInput optionText={(choice) => `${choice.name}`} />
    </ReferenceInput>
    <ReferenceInput
      source="appUserId"
      reference="appusers"
      label="resources.stockinrequests.fields.user"
      alwaysOn
    >
      <AutocompleteInput optionText={(choice) => `${choice.username}`} />
    </ReferenceInput>
  </Filter>
);

const rowClick = memoize((permissions) => (id, basePath, record) => {
  return permissions === "WM"
    ? Promise.resolve("edit")
    : Promise.resolve("edit");
});

class TabbedDatagrid extends React.Component {
  state = { stocking_in: [], stocked_in: [], cancelled: [] };

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, status: value });
  };

  render() {
    const { classes, filterValues, translate, permissions, ...props } =
      this.props;

    return (
      <Fragment>
        <Tabs
          fullWidth
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          <Tab
            key="stocking_in"
            label={translate(`resources.stockinrequests.tab.stocking_in`)}
            value="stocking_in"
          />
          <Tab
            key="stocked_in"
            label={translate(`resources.stockinrequests.tab.stocked_in`)}
            value="stocked_in"
          />
          <Tab
            key="cancelled"
            label={translate(`resources.stockinrequests.tab.cancelled`)}
            value="cancelled"
          />
        </Tabs>
        <Divider />
        <Responsive
          xsmall={
            <MobileGrid {...props} ids={this.state[filterValues.status]} />
          }
          medium={
            <div>
              {filterValues.status === "stocking_in" && (
                <Datagrid
                  {...props}
                  ids={this.state.stocking_in}
                  //   expand={<StockInEmbedded permissions={permissions} />}
                >
                  <TextField source="id" />
                  <ReferenceField
                    source="appUserId"
                    reference="appusers"
                    label="resources.stockinrequests.fields.user"
                  >
                    <TextField source="username" />
                  </ReferenceField>
                  <ProductReferenceField label="resources.stockinrequests.fields.product" />
                  {/* <StatusField /> */}
                  <NumberField source="boxes" />
                  <NumberField source="qtyInBox" />
                  <NumberField source="weightPerBox" />
                  <NumberField source="qtyInTailBox" />
                  <NumberField source="qty" />
                  <NumberField source="qtyPending" />
                  <TextField source="waybill" />
                  <CreatedField label="created" />
                  <DateField source="estArrivalDate" />
                  <TextField source="shippingCompany" />
                  <EditButton />
                  {permissions === "WM" ? <StockInButton /> : ""}
                </Datagrid>
              )}
              {filterValues.status === "stocked_in" && (
                <Datagrid
                  {...props}
                  ids={this.state.stocked_in}
                  expand={<StockInEmbedded permissions={permissions} />}
                >
                  <TextField source="id" />
                  <ReferenceField
                    source="appUserId"
                    reference="appusers"
                    label="resources.stockinrequests.fields.user"
                  >
                    <TextField source="username" />
                  </ReferenceField>
                  <ProductReferenceField label="resources.stockinrequests.fields.product" />
                  {/* <StatusField /> */}
                  <NumberField source="boxes" />
                  <NumberField source="qtyInBox" />
                  <NumberField source="weightPerBox" />
                  <NumberField source="qtyInTailBox" />
                  <NumberField source="qty" />
                  <NumberField source="qtyPending" />
                  <TextField source="waybill" />
                  <CreatedField label="created" />
                  <DateField source="estArrivalDate" />
                  <TextField source="shippingCompany" />
                </Datagrid>
              )}
              {filterValues.status === "cancelled" && (
                <Datagrid
                  {...props}
                  ids={this.state.cancelled}
                  expand={<StockInEmbedded permissions={permissions} />}
                >
                  <TextField source="id" />
                  <ReferenceField
                    source="appUserId"
                    reference="appusers"
                    label="resources.stockinrequests.fields.user"
                  >
                    <TextField source="username" />
                  </ReferenceField>
                  <ProductReferenceField label="resources.stockinrequests.fields.product" />
                  <NumberField source="boxes" />
                  <NumberField source="qtyInBox" />
                  <NumberField source="weightPerBox" />
                  <NumberField source="qtyInTailBox" />
                  <NumberField source="qty" />
                  <NumberField source="qtyPending" />
                  <TextField source="waybill" />
                  <CreatedField label="created" />
                  <DateField source="estArrivalDate" />
                  <TextField source="shippingCompany" />
                </Datagrid>
              )}
            </div>
          }
        />
      </Fragment>
    );
  }
}

const StockInRequestList = ({ permissions, ...props }) => (
  <List
    {...props}
    filterDefaultValues={{ status: "stocking_in" }}
    // sort={{ field: "estArrivalDate", order: "DESC" }}
    perPage={25}
    filters={<StockInRequestFilter />}
    bulkActionButtons={false}
  >
    <TabbedDatagrid permissions={permissions} />
  </List>
);

const enhance = compose(translate);

export default enhance(StockInRequestList);
