/* eslint react/jsx-key: off */
import memoize from "lodash/memoize";
import ProductReferenceField from "../products/ProductReferenceField";

import React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  Responsive,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  SimpleList,
  TextField,
  ReferenceField,
  DateField,
} from "react-admin";

const StockInFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <ReferenceInput
      source="productId"
      reference="products"
      label="resources.stockins.fields.product"
      alwaysOn
    >
      <AutocompleteInput optionText={(choice) => `${choice.name}`} />
    </ReferenceInput>
    <ReferenceInput
      source="appUserId"
      reference="appusers"
      label="resources.stockins.fields.user"
      alwaysOn
    >
      <AutocompleteInput optionText={(choice) => `${choice.username}`} />
    </ReferenceInput>
  </Filter>
);

const rowClick = memoize((permissions) => (id, basePath, record) => {
  return permissions === "WM"
    ? Promise.resolve("show")
    : Promise.resolve("show");
});

const StockInList = ({ classes, permissions, ...props }) => (
  <List
    {...props}
    filters={<StockInFilter permissions={permissions} />}
    sort={{ field: "stockInDate", order: "DESC" }}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) =>
            permissions === "admin" ? record.role : null
          }
        />
      }
      medium={
        <Datagrid rowClick={rowClick(permissions)}>
          <TextField source="id" />
          <ProductReferenceField label="resources.stockins.fields.product" />
          <TextField source="qty" />
          <DateField source="stockInDate" />
          <ReferenceField
            source="appUserId"
            reference="appusers"
            label="resources.stockins.fields.user"
          >
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField
            source="stockInRequestId"
            reference="stockinrequests"
            label="resources.stockins.fields.stock_in_request"
          >
            <TextField source="id" />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      }
    />
  </List>
);

export default StockInList;
