/* eslint react/jsx-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Show, SimpleShowLayout, TextField, DateField, ReferenceField } from 'react-admin'; // eslint-disable-line import/no-unresolved
import CurrentBoxLabelField from './CurrentBoxLabelField';
import CurrentImageListField from './CurrentImageListField';

const StockOutRequestShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Product" source="productId" reference="products">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="qty" />
            <TextField source="warehouse" />
            <TextField source="shippingCompany" />
            <TextField source="barcode" />
            <TextField source="speed" />
            <CurrentBoxLabelField label="resources.stockoutrequests.fields.current_boxLabel" />
            <CurrentImageListField label="resources.stockoutrequests.fields.current_images" />
            <DateField source="stockOutDate" />
            <TextField source="status" />
            <ReferenceField label="Account" source="accountId" reference="accounts">
                <TextField source="code" />
            </ReferenceField>
            <ReferenceField label="User" source="appUserId" reference="appusers">
                <TextField source="username" />
            </ReferenceField>
            <TextField source="note" />



        </SimpleShowLayout>
    </Show>
);

StockOutRequestShow.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default StockOutRequestShow;
