import React from "react";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import { withStyles } from "@material-ui/core/styles";
import {
  DateField,
  EditButton,
  translate,
  NumberField,
  TextField,
  ReferenceField,
} from "react-admin";
const listStyles = (theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem 0",
  },
  cardTitleContent: {
    display: "flex",
    flexDirection: "rows",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardContent: theme.typography.body1,
  cardContentRow: {
    display: "flex",
    flexDirection: "rows",
    alignItems: "center",
    margin: "0.5rem 0",
  },
});

const CardMediaField = (props) => {
  const { source, record } = props;
  return (
    <CardMedia
      component="img"
      height="150"
      image={record[source]}
      alt=""
    ></CardMedia>
  );
};

const MobileGrid = (props) => {
  const { classes, ids, data, basePath, translate } = props;
  return (
    <div style={{ margin: "1em" }}>
      {ids.map((id) => {
        return (
          <Card key={id} style={{ marginBottom: 30 }}>
            <CardHeader
              title={
                <ReferenceField
                  label="Product"
                  source="productId"
                  reference="products"
                  {...props}
                  className=""
                  record={data[id]}
                >
                  <TextField source="name" />
                </ReferenceField>
              }
            />
            <ReferenceField
              label="Product"
              source="productId"
              reference="products"
              {...props}
              className=""
              record={data[id]}
            >
              <CardMediaField source="image" />
            </ReferenceField>
            <CardContent>
              <div className={classes.cardContentRow}>
                {translate("resources.stockinrequests.fields.estArrivalDate")}
                :&nbsp;
                <DateField record={data[id]} source="estArrivalDate" />
              </div>
              <div className={classes.cardContentRow}>
                {translate("resources.stockinrequests.fields.qty")}
                :&nbsp;
                <NumberField
                  record={data[id]}
                  source="qty"
                  className={classes.total}
                />
              </div>
              <div className={classes.cardContentRow}>
                {translate("resources.stockinrequests.fields.waybill")}:&nbsp;
                <TextField record={data[id]} source="waybill" />
              </div>
            </CardContent>
            <CardActions
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <EditButton
                resource="commands"
                basePath={basePath}
                record={data[id]}
              />
            </CardActions>
          </Card>
        );
      })}
    </div>
  );
};

MobileGrid.defaultProps = {
  data: {},
  ids: [],
};

const enhance = compose(withStyles(listStyles), translate);

export default enhance(MobileGrid);
