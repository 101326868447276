/* eslint react/jsx-key: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import {translate} from 'react-admin';

class StockInButton extends Component {
    
    handleClick = (event) => {
        event.stopPropagation();
        const { record } = this.props;
        window.location = '#/stockins/create?request_id='+record.id+'&product_id='+record.productId+'&qty='+record.qtyPending+'&warehouse='+record.warehouse;   
    }

    render() {
        return (
            <Button label="stock_in" onClick={this.handleClick} color="primary" variant="raised">{this.props.translate('resources.stockinrequests.actions.stock_in')}</Button>
        );
    }
}

StockInButton.propTypes = {
    record: PropTypes.object,

};

const enhance = compose(
    translate
);

export default enhance(StockInButton);