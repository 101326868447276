/* eslint react/jsx-key: off */
import React from "react";
import PropTypes from "prop-types";
import {
  Edit,
  FormTab,
  SelectInput,
  ReferenceInput,
  ImageInput,
  ImageField,
  TabbedForm,
  TextInput,
  NumberInput,
  required,
  SaveButton,
  Toolbar,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";

import ProductTitle from "./ProductTitle";

const styles = {
  inlineBlock: { display: "inline-flex", marginRight: "8px", width: "80px" },
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProductEdit = ({ permissions, ...props }) => {
  return (
    <Edit title={<ProductTitle />} {...props} actions={false}>
      <TabbedForm defaultValue={{ role: "user" }} toolbar={<CustomToolbar />}>
        <FormTab label="resources.products.form.summary" path="">
          <TextInput source="name" defaultValue="" validate={required()} />
          {permissions === "WM" ? (
            <NumberInput
              source="qty_uk"
              label="resources.products.fields.qty"
              validate={required()}
            />
          ) : (
            <NumberInput
              source="qty_uk"
              label="resources.products.fields.qty"
              validate={required()}
              disabled
            />
          )}
          <NumberInput source="product_weight" />
          <NumberInput
            formClassName={props.classes.inlineBlock}
            source="size_l"
            label="resources.products.form.l"
          />
          <NumberInput
            formClassName={props.classes.inlineBlock}
            source="size_w"
            label="resources.products.form.w"
          />
          <NumberInput
            formClassName={props.classes.inlineBlock}
            source="size_h"
            label="resources.products.form.h"
          />
          <TextInput source="asin" />
          <TextInput source="w_sku" label="resources.products.form.w_sku" />

          <TextInput
            source="position_in_warehouse"
            label="resources.products.fields.position"
            // disabled={permissions !== "WM"}
          />
          <TextInput
            source="qtyInBox"
            label="resources.products.fields.qtyInBox"
            defaultValue=""
          />
          <TextInput
            source="box_size"
            label="resources.products.fields.box_size"
            defaultValue=""
          />
          <TextInput
            source="box_weight"
            label="resources.products.fields.box_weight"
            defaultValue=""
          />

          <ReferenceInput
            source="appUserId"
            allowEmpty
            emptyValue=""
            reference="appusers"
            label="resources.products.fields.user"
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
          <ImageInput
            source="image"
            accept="image/*"
            label="resources.products.fields.image"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <ImageField
            source="image"
            title="title"
            label="resources.products.fields.current_image"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

ProductEdit.propTypes = {
  id: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  permissions: PropTypes.string,
};

export default withStyles(styles)(ProductEdit);
