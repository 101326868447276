/* eslint react/jsx-key: off */
import React from "react";
import {
  Create,
  FormTab,
  SaveButton,
  TabbedForm,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  DateInput,
  Toolbar,
  required,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  hidden: {
    display: "none",
  },
};

const StockInRequestEditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveButton
      label="resources.actions.save_and_show"
      redirect="show"
      submitOnEnter={true}
    />
    {permissions === "admin" && (
      <SaveButton
        label="resources.actions.save_and_next"
        redirect={false}
        submitOnEnter={false}
        variant="flat"
      />
    )}
  </Toolbar>
);

const StockInRequestCreate = function ({ permissions, ...props }) {
  const urlParams = new URLSearchParams(props.location.search);
  const { classes } = props;
  const productId = urlParams.get("product_id")
    ? parseInt(urlParams.get("product_id"))
    : "";
  const userId = JSON.parse(localStorage.getItem("lbtoken")).value.userId;

  return (
    <Create {...props}>
      <TabbedForm
        toolbar={<StockInRequestEditToolbar permissions={permissions} />}
      >
        <FormTab label="resources.stockinrequests.form.summary" path="">
          <ReferenceInput
            source="productId"
            reference="products"
            perPage={100}
            label="resources.stockinrequests.fields.product"
            validate={required()}
            defaultValue={productId}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput source="boxes" defaultValue="" validate={required()} />
          <NumberInput
            source="qtyInBox"
            defaultValue=""
            validate={required()}
          />
          <NumberInput
            source="weightPerBox"
            defaultValue=""
            validate={required()}
          />
          <NumberInput
            source="qtyInTailBox"
            defaultValue="0"
            validate={required()}
          />
          <NumberInput source="qty" defaultValue="" validate={required()} />
          <TextInput source="waybill" defaultValue="" />
          <DateInput source="estArrivalDate" />
          <TextInput source="shippingCompany" />
          <SelectInput
            className={classes.hidden}
            source="status"
            choices={[
              {
                id: "stocking_in",
                name: "resources.stockinrequests.tab.stocking_in",
              },
              {
                id: "stocked_in",
                name: "resources.stockinrequests.tab.stocked_in",
              },
              {
                id: "cancelled",
                name: "resources.stockinrequests.tab.cancelled",
              },
            ]}
            defaultValue="stocking_in"
            validate={required()}
          />
          <ReferenceInput
            className={classes.hidden}
            source="appUserId"
            reference="appusers"
            label="resources.stockinrequests.fields.user"
            validate={required()}
            defaultValue={
              JSON.parse(localStorage.getItem("lbtoken")).value.userId
            }
          >
            <SelectInput optionText="username" />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default withStyles(styles)(StockInRequestCreate);
